import * as React from 'react';
import Homepage from './index';

const Design = () => {
  return (
    <Homepage path="/design"/>
  );
};

export default Design;
